/**
 * Controls light/dark mode
 *
 * Same approach as here
 * @see https://www.mattstobbs.com/remix-dark-mode/
 * however I use local storage to save theme preference
 * don't see the point of storing it in session
 */
import { createContext, useContext, useEffect, useState } from "react";
import type { Dispatch, ReactNode, SetStateAction } from 'react';

enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
}

const prefersDarkMQ = '(prefers-color-scheme: dark)';

type ThemeContextType = [Theme | null, Dispatch<SetStateAction<Theme | null>>];

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

function ThemeProvider({ children }: { children: ReactNode }) {
  const [theme, setTheme] = useState<Theme | null>(() => {
    // there's no way for us to know what the theme should be in this context
    // the client will have to figure it out before hydration.
    if (typeof window !== 'object') {
      return null;
    }

    // Get value set in local storage or use browser defined
    return localStorage.getItem('theme') === 'dark'
        || (!('theme' in localStorage) && window.matchMedia(prefersDarkMQ).matches)
      ? Theme.DARK
      : Theme.LIGHT;
  });

  /**
   * If the user updates their system theme preference while they have our app open, we can assume that they will want
   * our app’s theme to match their latest system preference. We can do this by adding an event listener
   * to the match media query to update the theme if it changes.
   */
  useEffect(() => {
    const mediaQuery = window.matchMedia(prefersDarkMQ);
    const handleChange = () => {
      setTheme(mediaQuery.matches ? Theme.DARK : Theme.LIGHT);
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return <ThemeContext.Provider value={[theme, setTheme]}>{children}</ThemeContext.Provider>;
}

function useTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}

/**
 * Script which executes before React will adjust server-side content to match client-side, to avoid mismatch error
 */
const clientThemeCode = `
;(() => {
  const theme = (localStorage.getItem('theme') === 'dark' || (!('theme' in localStorage) && window.matchMedia(${JSON.stringify(prefersDarkMQ)}).matches))
    ? 'dark'
    : 'light';

  const cl = document.documentElement.classList;
    
  if (!cl.contains('light') && !cl.contains('dark')) {
    cl.add(theme);
  }
  
  const meta = document.querySelector('meta[name=color-scheme]');
  if (meta) {
    meta.content = theme === 'dark' ? 'dark' : 'light';
  }
})();
`;


function ThemeInitScript() {
  const [theme] = useTheme();

  return <>
    <meta name="color-scheme" content={theme === "dark" ? "dark" : "light"} />
    <script dangerouslySetInnerHTML={{ __html: clientThemeCode }} />
  </>
}

export { ThemeInitScript, Theme, ThemeProvider, useTheme };